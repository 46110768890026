import React, { Component, useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import Steps from "../Steps";
import Wrapper from "../Wrapper";
import NewUser from "../NewUser"
import { Grid, Button, ListItem, FormLabel, List, TextField, Step, useMediaQuery } from '@material-ui/core'
import * as _ from "lodash";
import { MorningHours, EveningHours } from '../HoursListItem/index'

import './hours.css'
import { apiSmart } from '../../services/apiSmart'
import { backEnd } from '../../services/apiCheckCpf'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));
export default (props) => {
  const classes = useStyles();
  const schedule = JSON.parse(localStorage.getItem("labsp/schedule"))
  const date = new Date(schedule.day)
  const dateSelect = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  const [day, setDay] = useState(dateSelect)
  const [hoursfree, setHoursfree] = useState([])
  const [loading, setLoading] = useState(false)

  const [hour, setHour] = useState((schedule.hour ? schedule.hour : ""))


  const matches = useMediaQuery('(max-width:600px)') ? { 'width': '100%' } : { 'width': '20%' }

  useEffect(() => {
    if (schedule.cep) {

      async function getDayAndHour() {
        setLoading(true)
        const dateMore30 = date.addDays(20);

        var lastDay = `${dateMore30.getFullYear()}-${dateMore30.getMonth() + 1}-${dateMore30.getDate()}`
        let day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

        // let dayAndHour =  await apiSmart.getAddress(schedule.cep, day)
        let dayAndHour = await backEnd.getDateHour(schedule.cep, day, lastDay, true)
        
        var horaAtual = new Date()
        horaAtual = horaAtual.getHours()
        var amanha = new Date()
        amanha = amanha.getDate()+1
        var diaSelecionado = date.getDate()
        var i = 0
        
        if(dayAndHour && diaSelecionado == amanha-1){
          var horariosLivres = dayAndHour.data.HorariosLivre
          dayAndHour.data.HorariosLivre = []
          horariosLivres.forEach(function(number){
            var horaReserva = number.DataHoraIni
            horaReserva = horaReserva.split("T")[1]
            horaReserva = horaReserva.split(":")[0]
            if(horaAtual < 12){
              if(horaReserva > 15){
                dayAndHour.data.HorariosLivre[i] = number
                i++
              }
            }
          })
          if(horaAtual < 12){
            setHoursfree(dayAndHour.data || [])
          }else{
            setHoursfree({ HorariosLivre: false } || [])
          }
        }else if(dayAndHour && diaSelecionado == amanha) {
          var horariosLivres = dayAndHour.data.HorariosLivre
          dayAndHour.data.HorariosLivre = []
          horariosLivres.forEach(function(number){
            var horaReserva = number.DataHoraIni
            horaReserva = horaReserva.split("T")[1]
            horaReserva = horaReserva.split(":")[0]
            if(horaReserva > 9){
              dayAndHour.data.HorariosLivre[i] = number
              i++
            }
          })
          setHoursfree(dayAndHour.data || [])
        }else if(dayAndHour) {
          setHoursfree(dayAndHour.data || [])
        }else {
          setHoursfree({ HorariosLivre: false } || [])
        }
        setLoading(false)
      }

      getDayAndHour()
    }

  }, [])


  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


  const getHours = () => {

    let dayAndHour = JSON.parse(localStorage.getItem("labsp/dayAndHours"))


    return ["08:00", "09:00", "10:00", "11:00"]
  }

  const postHour = (hour) => {
    let schedule = JSON.parse(localStorage.getItem("labsp/schedule"))
    schedule.hour = hour
    localStorage.setItem("labsp/schedule", JSON.stringify(schedule))
    setHour(hour)
    props.daySelect()

  }


  return (
    <List component="nav">
      {
        loading &&
        <>
          <p>Estamos Verificando os horários disponíveis para o dia {day}</p>
          <CircularProgress />
        </>
      }
      {
        !loading && !hoursfree.HorariosLivre &&
        <span>Infelizmente não temos disponibilide para CEP informado</span>
      }
      {
        hoursfree && hoursfree.HorariosLivre &&
        <span>Encontramos os seguintes horários disponíveis, selecione o melhor horário para o seu atendimento no dia - {day}</span>
      }
      <div className="containerHours">

        <div className="containerHoursMorning">
          {hoursfree.HorariosLivre && (_.orderBy(_.uniqBy(hoursfree.HorariosLivre, "DataHoraIni"), ['DataHoraIni'], ['asc'])).map((e) => {

            let isMorning = e.DataHoraIni.substring(11, 13) >= 12 ? false : true;
            return (
              <>
                {isMorning &&
                  <MorningHours onClick={() => postHour(e.DataHoraIni)} key={e.DataHoraIni} matches={matches} hour={hour} hoursfree={e} />
                }

              </>
            )
          })}
        </div>

        <div className="containerHoursEvening">
          {hoursfree.HorariosLivre && (_.orderBy(_.uniqBy(hoursfree.HorariosLivre, "DataHoraIni"), ['DataHoraIni'], ['asc'])).map((e) => {
            let isMorning = e.DataHoraIni.substring(11, 13) >= 12 ? false : true;
            return (
              <>
                {!isMorning &&

                  <EveningHours onClick={() => postHour(e.DataHoraIni)} key={e.DataHoraIni} matches={matches} hour={hour} hoursfree={e} />}

              </>
            )
          })}
        </div>
      </div>
    </List>

  )

}

