import React, { useState, useEffect } from 'react'
import Wrapper from "../Wrapper";
import Title from "../Title";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
// import { Link, Redirect } from "react-router-dom";
// import { Steps } from "../../components/steps";
import './sucesso.css'
import icon from './icon.svg'
import { apiSmart } from '../../services/apiSmart'
import axios from 'axios'

export default () => {
  useEffect(() => {
    apiSmart.confirmSchedule()
  }, [])

  useEffect(() => {
    sendEmailToUser();
    sendEmailToAttendence();
  }, [])

  const [redirect, setRedirect] = useState(false)

  async function sendEmailToUser() {
    let schedule = JSON.parse(localStorage.getItem("labsp/schedule"))

    const base = "https://backend-app-5834x.ondigitalocean.app/backendsp2";
    // const base = `https://backendsp-twqa7.ondigitalocean.app`;
    // const base = `http://localhost:8080`;

    const url = `${base}/sendEmail`;
    const request = await axios.post(url, {
      schedule
    });


    const success = request.data.success;

    if (!success) {
      console.log("Falha para enviar email!")
    }
  } 

  async function sendEmailToAttendence() {
    let schedule = JSON.parse(localStorage.getItem("labsp/schedule"))

    const base = "https://backend-app-5834x.ondigitalocean.app/backendsp2";
    // const base = `https://backendsp-twqa7.ondigitalocean.app`;
    // const base = `http://localhost:8080`;
    
    const url = `${base}/sendEmailAttendance`;
    const request = await axios.post(url, {
      schedule
    });


    const success = request.data.success;

    if (!success) {
      console.log("Falha para enviar email!")
    }
  }

  const fontText = {
    fontSize: '1.5rem'
  }

  return (

    <Wrapper>
      {/* {redirect &&
                <Redirect to="/" />
             } */}
      <Grid container spacing={1} className="grid">
        <>
          <Grid item xs={12}>
            <img src={icon}></img>
            <p style={fontText}>
              Seu Agendamento Foi Realizado Com Sucesso, você receberá todas as informações de
              confirmação em seu e-mail.
              <br />
              Se você realizou o agendamento de teste de covid, você tem a opção de já realizar o
              pagamento online.
              <br />
            </p>
          </Grid>


        </>

      </Grid>


    </Wrapper>
  )
}
