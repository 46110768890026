import React from 'react'
import Wrapper from "../Wrapper";
import Grid from "@material-ui/core/Grid";
import './sucesso.css'
import icon from './icon.svg'

export default () => {

  const fontText = {
    fontSize: '1.5rem'
  }

  return (

    <Wrapper>
      <Grid container spacing={1} className="grid">
        <>
          <Grid item xs={12}>
            <img src={icon}></img>
            <p style={fontText}>
              Houve um problema para realizar o agendamento.
              <br />
              Por favor, tente agendar outro horário ou entre em contato conosco.
              <br />
            </p>
          </Grid>


        </>

      </Grid>


    </Wrapper>
  )
}
